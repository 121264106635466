@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
/* Fondo general */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Fredoka One", sans-serif;
}

form.form-location {
  background-color: #f8f8f9;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 1080px;
  width: 80%;
  margin: 40px auto; /* 👈 centrado vertical con espacio */
  z-index: 20;
  margin: -450px auto 40px; /* 👈 este truco hace que suba sobre la imagen */
  @media screen and (max-width: 1024px) {
    width: 85%;

    padding: 20px;
    margin: -800px auto 20px; /* Ajusta el valor negativo para mobile */
  }
  @media screen and (max-width: 768px) {
    width: 80%;
    max-width: 400px;
    padding: 20px;
    margin: -40px auto 20px; /* Ajusta el valor negativo para mobile */
  }
}

/* Título principal */
h1,
h2 {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #007bff; /* Azul principal */
}

/* Inputs */
input,
select,
textarea {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 15px;
  width: 100%;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #007bff;
  outline: none;
}

/* Botones */
button {
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.header {
  background-image: url("/public/header-background.svg"); /* Cambia por tu imagen */
  background-size: cover;
  background-position: center;
  height: 200px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 10px 10px;
  position: relative;
}

.header-content h1 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.header-content p {
  margin: 5px 0 0 0;
  font-size: 16px;
  font-weight: 300;
}

.language-selector {
  position: absolute;
  top: 10px;
  right: 10px;
}

.language-selector button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.language-selector button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.input-titles {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 14.63px !important;
}

.input-instructions {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14.63px !important;
  margin-top: 13px !important;
  margin-left: 5px !important;
}

/* styles.css */
.marker-drop {
  animation: drop 0.5s ease-out;
}

@keyframes drop {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
